@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");

/*TODO: get this from design-system*/

* {
    margin: 0;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h5,
h6 {
    margin-bottom: 0px;
}

h1.title,
h2.title,
h3.title,
h4.title,
h5.title,
h5.title,
h6.title {
    font-weight: 500;
    color: #2d3446;
    width: 100%;
    margin: 0 0;
    margin-bottom: 30px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
}

h1.title:before,
h2.title:before,
h3.title:before,
h4.title:before,
h5.title:before,
h5.title:before,
h6.title:before {
    content: "";
    width: 5px;
    height: 40px;
    background-color: #e1e6ec;
    display: -ms-flexbox;
    display: flex;
    margin-right: 15px;
}

h1.title:after,
h2.title:after,
h3.title:after,
h4.title:after,
h5.title:after,
h5.title:after,
h6.title:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #e1e6ec;
    display: -ms-flexbox;
    display: flex;
    margin-left: 15px;
}

ol,
ul,
dl {
    margin-bottom: 0px;
    list-style: none;
}

html,
html a {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    overflow: hidden;
}

body {
    font-size: 12px;
    font-family: "Roboto", sans-serif, sans-serif;
    color: #1e283e;
    overflow: hidden;
    overflow-y: auto;
}

img {
    vertical-align: middle;
    border-style: none;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

/*END*/
